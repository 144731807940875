function actionMenu() {
    if(window.innerWidth>1120) {
        const header = document.querySelector('.header')
        window.addEventListener('scroll',function() {
            if(window.scrollY>821) {
                if(!header.classList.contains('white')) {
                    header.classList.add('white')
                }
                else {
                    return
                }
            }
            else {
                if(header.classList.contains('white')) {
                    header.classList.remove('white')
                }
                else {
                    return
                }
            }
        })
    }
    if(window.innerWidth<1120 && window.innerWidth >475) {
        const mobileHeader = document.querySelector('.mobile-header');
        window.addEventListener('scroll', function() {
            if(window.scrollY>820) {
                if(!mobileHeader.classList.contains('white')) {
                    mobileHeader.classList.add('white');
                }
                else {
                    return
                }
            }
            else {
                if(mobileHeader.classList.contains('white')) {
                    mobileHeader.classList.remove('white');
                }
                else {
                    return
                }
            }
        })
    }
    if(window.innerWidth < 475) {
        const mobileHeader = document.querySelector('.mobile-header');
        window.addEventListener('scroll', function() {
            if(window.scrollY>564) {
                if(!mobileHeader.classList.contains('white')) {
                    mobileHeader.classList.add('white');
                }
                else {
                    return
                }
            }
            else {
                if(mobileHeader.classList.contains('white')) {
                    mobileHeader.classList.remove('white');
                }
                else {
                    return
                }
            }
        })
    }
}
module.exports = actionMenu;