function modalBtn(handle) {
    const modalBtns = document.querySelectorAll('.modalBtn');
    modalBtns.forEach(btn=>{
        let modal = document.querySelector('.'+btn.dataset.expand);
        btn.addEventListener('click',function() {
            document.body.style.overflow = 'hidden';
            document.querySelector('.call-popup').classList.remove('active');
            modal.classList.add('active');
            handle();
        })
    })
}
module.exports = modalBtn;