function dotContainer(gap=0) {
    const dottedSliders = document.querySelectorAll('.dotted');
    dottedSliders.forEach(slider=>{
        const childrens =Array.from(slider.children).filter(child => child !=="");


        const childCount = childrens.length;
        let childwidth = childrens[0].clientWidth;
        let totalChildWidh = childwidth + gap;
        let dotWrapper = document.createElement('div');
        dotWrapper.classList.add('dot-wrapper');
        for(let i = 0 ; i < childCount ; i++) {
            let dot = document.createElement('div');
            dot.classList.add('dot');
            dotWrapper.appendChild(dot)
        }
        slider.closest('.dot-container').appendChild(dotWrapper);
        dotWrapper.querySelector('.dot').classList.add('active');
        slider.addEventListener('scroll', function(){
            let dots = Array.from(dotWrapper.children).filter(child => child.tagName === 'DIV')
            let scrollAmount = slider.scrollLeft;
            current = Math.round(scrollAmount / totalChildWidh);
            if(!dots[current].classList.contains('active')) {
                dots.forEach(child=>{
                    child.classList.remove('active');
                })
                dots[current].classList.add('active');
            }
            else {
                return
            }
        });

    })
}
module.exports = dotContainer;