function mobileMenu() {
    const mobileNav = document.querySelector('.mobile-menu');
    let menuLinks = mobileNav.querySelectorAll('a');
    menuLinks.forEach(link=>{
        console.log(link);
        link.addEventListener('click', function(){
            link.closest('.modal').classList.remove('active');
        })
    })
}
module.exports = mobileMenu;