function forms() {


    const overlay = document.querySelector('.overlay');
    const popup = document.querySelector('.modal-popup');
    const messagePopup = document.querySelector('.popup-succes');

    document.querySelector('#okey1').addEventListener('click', function() {
        messagePopup.style.display = 'none';
        popup.style.display = 'block';
        overlay.style.display = 'none';
    })
    overlay.addEventListener('click' , function(event) {
        if(event.target.classList.contains('overlay')) {
            overlay.classList.remove('active');
            messagePopup.style.display = 'none'
            popup.style.display = 'block'
        }
    })
        popup.querySelector('.close-btn').addEventListener('click', function() {
            overlay.classList.remove('active');
            messagePopup.classList.remove('active');
            popup.style.display = 'block';
        })
    ////////формы
    
    let forms = document.querySelectorAll('form');
    forms.forEach(form=>{
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            let telValue = form.querySelector('.tel').value;
        if (!(telValue.length >= form.querySelector('.tel').minLength)) {
            return
        }
            showMessagePopup(popup,messagePopup,overlay);
           let formData = new FormData(form),
                doneMessage = 'Мы свяжемся с вами в течении рабочего дня.',
                undoneMessage ='Произошла ошибка на стороне сервера,попробуйте связаться иначе.';
                undoneTitle = "Упс, ошибка";
                doneTitle = 'Спасибо за заявку';
                undoneUrl = './vectors/error.svg';
                doneUrl = './vectors/succes.svg';
           fetch(form.dataset.action, {
            method: 'POST',
            body:formData,
           })
           .then(function(data) {
            console.log(data);
            removeMessagePopup(popup,messagePopup,overlay,doneMessage, doneTitle, doneUrl);
            form.querySelectorAll('input').forEach(input=>{
                input.value="";
            });
           })
           .catch(function(error){
            console.log(error);
                removeMessagePopup(popup,messagePopup,overlay,undoneMessage, undoneTitle, undoneUrl);
           });
        
        })
    })
    function showMessagePopup(popup,messagePopup,layout) {
        if(!layout.classList.contains('active')) {
            layout.classList.add('active');
        }
        popup.style.display = 'none';
        messagePopup.querySelector('img').style.display = 'none';
        messagePopup.querySelector('.spinner-container').style.display = 'flex';
        messagePopup.style.display ='block';
        document.body.style.overflow = 'hidden';
        messagePopup.querySelector('.succes__title').textContent = "";
        messagePopup.querySelector('.succes__subtitle').textContent = ""
        messagePopup.querySelector('img').style.display = 'none';
        messagePopup.querySelector('button').style.display = 'none';
    
    }
    function removeMessagePopup(popup,messagePopup,layout,textMessage,title,url) {
        messagePopup.querySelector('.spinner-container').style.display = 'none';
        messagePopup.querySelector('button').style.display = 'block';
    
        messagePopup.querySelector(".succes__subtitle").textContent= textMessage;
        messagePopup.querySelector('.succes__title').textContent = title;
        messagePopup.querySelector('img').style.display = 'block';
        messagePopup.querySelector('img').src = url;
        setTimeout(function(){
            layout.classList.remove('active')
            setTimeout(function(){
                document.body.style.overflow ='visible';
                popup.style.display='block';
                // document.querySelector('.validation-icon-error').style.display = 'none';
                // document.querySelector('.validation-icon-succes').style.display = 'none';
                // document.querySelector('.validation-message').style.display = 'none';
                messagePopup.style.display = 'none';
            },500)
        },2000);
    }
}
module.exports = forms;