function gallery() {
    const gallery = document.querySelector('.gallery');
    const galleryPreview = gallery.querySelector('.first-col-prev');
    const galleryItems = gallery.querySelectorAll('.gallery-item');
    const galleryPreviewImg = galleryPreview.querySelector('img');
    galleryPreviewImg.setAttribute('src',galleryItems[0].getAttribute('src'));
    galleryItems.forEach(item=>{
        item.addEventListener('click',function() {
            console.log('click');
            galleryPreviewImg.setAttribute('src', item.getAttribute('src'));
        })
    })
}
module.exports = gallery;