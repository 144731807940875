function calcQuestionItem() {
    let questions = document.querySelectorAll('.question-item');
    questions.forEach(item=>{
        const titleHeight = item.querySelector('.question-item__title').offsetHeight;
        item.style.height = titleHeight+'px';
        item.addEventListener('click', function() {
            item.classList.toggle('question-item-active');
            if(item.classList.contains('question-item-active')) {
                item.style.height = item.clientHeight + item.querySelector('.question-item__descr').clientHeight +20+ 'px';
            }
            else {
                item.style.height = titleHeight+'px';
            }
        })
    })

}
module.exports = calcQuestionItem;