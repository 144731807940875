function slider(slider,gap=0) {
    const sliders = document.querySelectorAll('.'+slider);
    sliders.forEach(slider=>{
        const sliderContainer = slider.querySelector('.slider-wrapper');
    const nextBtn = slider.querySelector('.next');
    const prevBtn = slider.querySelector('.prev');
    const sliderItem = sliderContainer.querySelector('div'||'a');
    const itemWidth = sliderItem.clientWidth;
    const totalItemWidth = itemWidth + gap;
    ////////////////////////////////
    let scrollAmount = 0;
    let maxScrollAmount = sliderContainer.scrollWidth - sliderContainer.clientWidth;

    nextBtn.addEventListener('click', () => {
        if(scrollAmount >= maxScrollAmount){
            return
        }
        else {
            scrollAmount += totalItemWidth;
        sliderContainer.scrollLeft = scrollAmount;
        }
    });

    prevBtn.addEventListener('click', () => {
       if(scrollAmount<=0) {
        return
       }
       else {
        scrollAmount -= totalItemWidth;
        sliderContainer.scrollLeft = scrollAmount;
       }
    });
    })
}
module.exports = slider;