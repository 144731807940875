function getRowsContainer(className, quantity = 6) {
    const rowsContainers = document.querySelectorAll('.' + className);
    
    rowsContainers.forEach(rowContainer => {
        // Получаем все дочерние элементы с классом 'item'
        const items = Array.from(rowContainer.querySelectorAll('.item'));
        
        // Определяем количество блоков item-wrap
        const wrapCount = Math.ceil(items.length / quantity);
        
        for (let i = 0; i < wrapCount; i++) {
            // Создаем новый блок item-wrap
            const itemWrap = document.createElement('div');
            itemWrap.classList.add('item-wrap');
            
            // Определяем, сколько элементов мы можем переместить в текущий item-wrap
            const start = i * quantity;
            const end = start + quantity;
            const itemsToMove = items.slice(start, end);
            
            // Перемещаем элементы в новый блок item-wrap
            itemsToMove.forEach(item => {
                itemWrap.appendChild(item);
            });
            
            // Добавляем новый блок item-wrap в контейнер
            rowContainer.appendChild(itemWrap);
        }
    });
}
module.exports = getRowsContainer;