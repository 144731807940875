document.addEventListener("DOMContentLoaded", function() {
    let calcQuestionItem = require('./modules/questions-item');
    let modalBtn = require('./modules/modalBtn');
    let getRowsContainer = require('./modules/rows-container');
    let slider = require('./modules/slider');
    let mobileMenu = require('./modules/mobile-menu');
    mobileMenu();
    const dotContainer = require('./modules/dot-container');
    dotContainer();
    let call = require('./modules/call-btn');
    call();
    const actionMenu = require('./modules/menu-action');
    actionMenu();
    slider('slider', 20);
    slider('first-screen-slider');
    let gallery = require('./modules/gallery');
    let videoPreview = require('./modules/video-preview');
    videoPreview();
    const mask = require('./modules/mask');
    mask();
    if(window.innerWidth>475) {
        getRowsContainer('row-6');
        getRowsContainer('row-5',5);
        gallery();
    }
    const ajaxForms = require('./modules/ajax-form');
    ajaxForms();
    modalBtn(calcSectionHeight);
    calcQuestionItem();
    function calcSectionHeight() {
        const sectionsWrappers = document.querySelectorAll('.section-wrapper');
        sectionsWrappers.forEach(item=>{
            const height = item.offsetHeight;
            item.closest('.section').style.height = height+"px";
        })
    }
    calcSectionHeight();
    const socialBanners = document.querySelectorAll('.social-banner');
    socialBanners.forEach(banner=>{

        const bannerPresets = {
            telegram:{
                label:"",
                сontent:"Ссылка на телегу",
                link:"#"
            },
            whatsapp:{
                label:"",
                сontent:"Ссылка на whatsapp",
                link:"#"
            },
            telephone:{
                label:"Номер телефона",
                сontent:"+7 912 234-33-11",
                link:"tel:+79122343311"
            }
        }

        let select = banner.querySelector('.social-banner-select');
        let options = banner.querySelector('.options');
        let selecteds = banner.querySelectorAll('.social-banner-selected');
        let label = banner.querySelector('.label');
        let current = banner.querySelector('.current');
        let button = banner.querySelector('.button');
        select.addEventListener('click', function(){
            options.classList.toggle('active');
        });
        options.addEventListener('click', function(e) {
            selecteds.forEach(select=>{
                select.classList.remove('active');
                options.classList.remove('active');
                if(e.target.classList.contains('telegram')) {
                    banner.querySelector('.telegram-select').classList.add('active');
                    label.textContent = bannerPresets.telegram.label;
                    current.textContent = bannerPresets.telegram.сontent;
                    button.setAttribute('href',bannerPresets.telegram.link);
                    
                }
                else if(e.target.classList.contains('whatsapp')) {
                    banner.querySelector('.whatsapp-select').classList.add('active');
                    label.textContent = bannerPresets.whatsapp.label;
                    current.textContent = bannerPresets.whatsapp.сontent;
                    button.setAttribute('href',bannerPresets.whatsapp.link);
                }
                else {
                    banner.querySelector('.telephone-select').classList.add('active');
                    label.textContent = bannerPresets.telephone.label;
                    current.textContent = bannerPresets.telephone.сontent
                    button.setAttribute('href',bannerPresets.telephone.link);
                }
            })
        })
    })
    const closeBtns = document.querySelectorAll('.close-btn');
    closeBtns.forEach(btn=>{
        btn.addEventListener('click', function() {
            document.body.style.overflow = 'visible';
            btn.closest('.modal').classList.remove('active');
        })
    })
})